<template>
  <el-dialog
    width="800px"
    :title="title"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :destroy-on-close="true"
  >
    <Player
      v-if="dialogVisible"
      :url="option.itemOptionsVideoUrl"
    />
  </el-dialog>
</template>

<script>
export default {
  components: {
    Player: () => import('@/components/Player/index.vue')
  },
  props: {
    option: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    title() {
      return `${this.option.itemOptionsChar}：${this.option.itemOptionsName}`;
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body{
  padding: 0 20px 20px;
}
</style>
